<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Client ID's List</h1>
                <button class="close_btn left_out" @click="closeModel"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="result_wpr new small m-0">
                    <div class="actions">
                        <ul>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter"  v-click-outside="closePageFilter">
                                Show {{ params.per_page }}<i class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :is-dropdown="true" :type="2" ref="contact-per-page-filter" />
                            </li>
                            <li class="optionDrops search_area ml-auto active">
                                <input type="text" class="p-0" placeholder="Search" @input="isTyping = true" v-model.trim="params.search" />
                                <button class="search_btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li>
                            <!-- <li class="list_info">
                                {{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of <span>{{ contacts.total ? contacts.total : 0 }}</span>
                            </li> -->
                        </ul>
                    </div>
                    <div v-if="contactsLoader"><line-loader /></div>
                    <div class="scroll_table">
                        <table class="standard show_header">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>E-mail</th>
                                    <th>Client ID</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="contacts.data && contacts.data.length">
                                <tr v-for="(contact, c) in contacts.data" :key="c">
                                    <td>
                                        <div class="user_wpr" @click="handleProfile(contact)">
                                            <h4>{{ contact.name }}</h4>
                                        </div>
                                    </td>
                                    <td>{{ contact.email }}</td>
                                    <td>{{ contact.member_id }}</td>
                                    <td class="action"><a @click="handleLaunchPad(contact)"><i class="fas fa-ellipsis-v"></i></a></td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="px-4 text-center">
                                        <div class="empty_box">
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Records Found</h4>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ contacts.from ? contacts.from : 0 }} - {{ contacts.to ? contacts.to : 0 }} of {{ contacts.total ? contacts.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="contacts.total">
                    <pagination v-model="params.page" :pages="contacts.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
                <div v-if="launchPad" class="details_wpr">
                    <div class="cardItem_details">
                        <div class="header">
                            <button class="close_btn" @click="launchPad = false"><i class="fas fa-chevron-down"></i></button>
                            <h2 class="pb-2">Launch Pad <span class="contact">{{ selectedContact.name }}</span></h2>
                            <ul class="tab_sec mt-3">
                                <li @click="launchPadTab = 1" :class="{ active: launchPadTab === 1 }">Actions</li>
                                <li @click="launchPadTab = 2" :class="{ active: launchPadTab === 2 }">Settings</li>
                                <li @click="launchPadTab = 3" :class="{ active: launchPadTab === 3 }">Advanced</li>
                            </ul>
                        </div>
                        <div class="tab_content">
                            <keep-alive v-if="launchPadTab == 1">
                                <action :contact="selectedContact" />
                            </keep-alive>
                            <keep-alive v-if="launchPadTab == 2">
                                <setting :contact="selectedContact" />
                            </keep-alive>
                            <keep-alive v-if="launchPadTab == 3">
                                <advance :contact="selectedContact" :tab-watcher="launchPadTab" />
                            </keep-alive>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModel">Close</button>
            </div>
        </div>
        <profile-component v-model="profile" :contact="selectedContact" :updateSelectedContact="updateSelectedContact" :is-id-list="true" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations } from 'vuex'

    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const Action = defineAsyncComponent(() => import('@/pages/contact/components/launch-pad/Action'))
    const Advance = defineAsyncComponent(() => import('@/pages/contact/components/launch-pad/Advance'))
    const Setting = defineAsyncComponent(() => import('@/pages/contact/components/launch-pad/Setting'))

    export default {
        name: 'Id List',

        data() {
            return {
                profile: false,
                launchPad: false,
                launchPadTab: 1,
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    type: 'all'
                },
                isTyping: false,
                selectedContact: {}
            }
        },

        props: {
            modelValue: Boolean,
        },

        components: {
            ProfileComponent,
            Action,
            Advance,
            Setting,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getContacts(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContacts(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContacts(vm.params);
                        }
                    }
                }
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    setTimeout(function () {
                        vm.getContacts(vm.params);
                    }, 2000);

                    vm.profile          = false;
                    vm.launchPad        = false;
                    vm.launchPadTab     = 1;
                    vm.selectedContact  = {}
                    vm.params           = { page: 1, per_page: 5, search: '', type: 'all' };
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            contacts: state => state.contactModule.contacts,
            contactsLoader: state => state.contactModule.contactsLoader,
        }),

        methods: {
            ...mapActions({
                getContacts: 'contactModule/getContacts',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            closeModel () {
                const vm = this;

                vm.profile = false;
                vm.launchPad = false;
                vm.$emit('update:modelValue', false)
            },

            handleLaunchPad(contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.launchPad = true;
            },

            handleProfile(contact) {
                const vm = this;

                vm.resetTimezoneByCountry([]);
                vm.selectedContact = contact;

                if (vm.profile) {
                    vm.profile = false;
                }

                setTimeout(function () {
                    vm.profile = true;
                }, 5);
            },

            updateSelectedContact (contact) {
                const vm = this;

                vm.getContacts(vm.params);
                vm.selectedContact = contact;
            },

            togglePerPageFilter () {
                const vm = this;
                const filter = vm.$refs['contact-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['contact-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContacts(vm.params);
            },
        }
    }
</script>

<style scoped>
    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child{
        width: auto;
        text-align: left;
    }
    .result_wpr.small table.standard td.action{
        width: 30px;
    }
    .result_wpr.small table.standard td.action i{
        width: auto;
        font-size: 13px;
    }
    .result_wpr.small table.standard td .drp_wrapper, .tempcard ul li .drp_wrapper{
        display: none;
    }
    .result_wpr.small table.standard td .drp_wrapper.active, .tempcard ul li .drp_wrapper.active{
        display: block;
    }
    @media(max-width: 767px){
        .result_wpr.small .actions{
            min-width: 100% !important;
        }
        .result_wpr.new.small .actions > ul li.search_area{
            width: 190px;
        }
        .result_wpr.new.small .actions > ul li.search_area input[type=text]{
            width: 100%;
            padding: 0 15px 0 0;
        }
    }
</style>
